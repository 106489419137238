// Here you can add other styles
body {
  font-family: 'Montserrat';
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}
.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}
.pointer {
  cursor: pointer;
}

.accept-terms {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.pdf-viewer-buttons {
  position: absolute;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  left: 50%; /* Align the left side of the element with the center of the parent */
  transform: translateX(-50%); /* Move the element left by 50% of its width */
  font-size: 0.875rem;
  z-index: 999;
  margin-bottom: 0;
}

.spinner-center {
  text-align: center;
}

.spinner-cover {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .5);
  z-index: 50;
}

@media (max-width: 575.98px) {
  .alert{
    font-size: 0.875rem;
  }
  /* Styles for screens smaller than 576px go here */
  .pdf-viewer-buttons {
    font-size: 1.1rem; /* Example of changing font-size */
    padding: 8px; /* Example of adjusting padding */
    transform: translateX(-50%);
    position: relative;
    text-align: center;
  }
  .btn{
    margin-top: 7px;
  }

  .pdf-viewer-buttons span{
    white-space: nowrap;
  }
  .ml-sm-15{
    margin-left: 15px;
  }
  input[type="checkbox"] {
    margin-top: 10px;
  }
}